var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          fields: _vm.fields,
          items: _vm.data,
          "sort-by": "bombsite",
          hover: "",
          striped: "",
          dark: ""
        },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c("b-th", { attrs: { colspan: "5" } }, [
                      _vm._v(" Win conditions ")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(bombsite)",
            fn: function(field) {
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("bombsite")(field.item.bombsite)) + " "
                )
              ]
            }
          },
          {
            key: "cell(condition)",
            fn: function(field) {
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("outcome")(field.item.condition)) + " "
                )
              ]
            }
          },
          {
            key: "cell(atk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.atk_wins))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.atk_wins_percentage) + "%")
                  ]),
                  _vm._v(" / "),
                  _c("strong", [_vm._v(_vm._s(field.item.atk_loses))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.atk_loses_percentage) + "%")
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.def_wins))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.def_wins_percentage) + "%")
                  ]),
                  _vm._v(" / "),
                  _c("strong", [_vm._v(_vm._s(field.item.def_loses))]),
                  _c("span", { staticClass: "text-muted small ml-1" }, [
                    _vm._v(_vm._s(field.item.def_loses_percentage) + "%")
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }