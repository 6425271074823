var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Compositions")]),
      _c("AppTable", {
        attrs: {
          items: _vm.teamCompositions,
          fields: _vm.compositionsFields,
          "tbody-tr-class": "cursor-pointer"
        },
        on: { "row-clicked": _vm.toggleDetails },
        scopedSlots: _vm._u([
          {
            key: "cell(agentIds)",
            fn: function(field) {
              return [
                _c(
                  "div",
                  { staticClass: "composition-cell" },
                  [
                    _c("BIconCaretRightFill", {
                      staticClass: "toggle-details-icon",
                      class: field.detailsShowing ? "expanded" : "collapsed"
                    }),
                    _c("AgentComposition", {
                      attrs: { agent_ids: field.item.agentIds, size: "sm" }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c("AppTable", {
                  attrs: { items: row.item.players, fields: _vm.playersFields },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(player)",
                        fn: function(field) {
                          return [
                            field.item.id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/player/" +
                                        field.item.id +
                                        "/stats/" +
                                        _vm.selected_map_id
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(field.item.game_name) + " "
                                    )
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(field.item.game_name) + " "
                                  )
                                ])
                          ]
                        }
                      },
                      {
                        key: "cell(agent)",
                        fn: function(field) {
                          return [
                            _c("img", {
                              staticClass: "agent-icon",
                              attrs: {
                                src: field.item.agent_display_icon_url,
                                alt: field.item.agent_name
                              }
                            }),
                            _c("span", [
                              _vm._v(" " + _vm._s(field.item.agent_name) + " ")
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }