var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppTable", {
    attrs: {
      items: _vm.tableItems,
      fields: _vm.fields,
      "sort-desc": false,
      "tbody-tr-class": "cursor-pointer",
      variant: "performance"
    },
    on: { "row-clicked": _vm.toggleDetails },
    scopedSlots: _vm._u([
      {
        key: "thead-top",
        fn: function() {
          return [
            _c(
              "b-tr",
              [
                _c("b-th", { attrs: { colspan: "3" } }, [
                  _vm._v(" Round Performance ")
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "cell(scenario)",
        fn: function(field) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.noninteractive",
                    modifiers: { hover: true, noninteractive: true }
                  }
                ],
                attrs: { title: field.item.title }
              },
              [
                _c("BIconCaretRightFill", {
                  staticClass: "toggle-details-icon",
                  class: field.detailsShowing ? "expanded" : "collapsed"
                }),
                _vm._v(" " + _vm._s(field.item.scenario) + " ")
              ],
              1
            )
          ]
        }
      },
      {
        key: "cell(atk)",
        fn: function(field) {
          return [
            field.item.scenario === "Trade rate"
              ? _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.total,
                          field.item.atk.deaths
                        )
                      )
                    )
                  ]),
                  field.item.atk.deaths
                    ? _c("span", { staticClass: "text-muted small ml-1" }, [
                        _vm._v(
                          "(" +
                            _vm._s(field.item.atk.total) +
                            "/" +
                            _vm._s(field.item.atk.deaths) +
                            ")"
                        )
                      ])
                    : _vm._e()
                ])
              : _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.wins,
                          field.item.atk.total
                        )
                      )
                    )
                  ]),
                  field.item.atk.total
                    ? _c("span", { staticClass: "text-muted small ml-1" }, [
                        _vm._v(
                          "(" +
                            _vm._s(field.item.atk.wins) +
                            "/" +
                            _vm._s(field.item.atk.total) +
                            ")"
                        )
                      ])
                    : _vm._e()
                ])
          ]
        }
      },
      {
        key: "cell(def)",
        fn: function(field) {
          return [
            field.item.scenario === "Trade rate"
              ? _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.total,
                          field.item.def.deaths
                        )
                      )
                    )
                  ]),
                  field.item.def.deaths
                    ? _c("span", { staticClass: "text-muted small ml-1" }, [
                        _vm._v(
                          "(" +
                            _vm._s(field.item.def.total) +
                            "/" +
                            _vm._s(field.item.def.deaths) +
                            ")"
                        )
                      ])
                    : _vm._e()
                ])
              : _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.wins,
                          field.item.def.total
                        )
                      )
                    )
                  ]),
                  field.item.def.total
                    ? _c("span", { staticClass: "text-muted small ml-1" }, [
                        _vm._v(
                          "(" +
                            _vm._s(field.item.def.wins) +
                            "/" +
                            _vm._s(field.item.def.total) +
                            ")"
                        )
                      ])
                    : _vm._e()
                ])
          ]
        }
      },
      {
        key: "row-details",
        fn: function(row) {
          return [
            _c("AppTable", {
              attrs: {
                items: row.item.ecoItems,
                fields: _vm.detailFields,
                "sort-by": "eco",
                "sort-desc": true,
                "sort-compare": _vm.sortCompare,
                "thead-class": "-d-none"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(atk)",
                    fn: function(field) {
                      return [
                        field.item.scenario === "Trade rate"
                          ? _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.atk.total,
                                      field.item.atk.deaths
                                    )
                                  )
                                )
                              ]),
                              field.item.atk.deaths
                                ? _c(
                                    "span",
                                    { staticClass: "text-muted small ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.atk.total) +
                                          "/" +
                                          _vm._s(field.item.atk.deaths) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.atk.wins,
                                      field.item.atk.total
                                    )
                                  )
                                )
                              ]),
                              field.item.atk.total
                                ? _c(
                                    "span",
                                    { staticClass: "text-muted small ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.atk.wins) +
                                          "/" +
                                          _vm._s(field.item.atk.total) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                      ]
                    }
                  },
                  {
                    key: "cell(def)",
                    fn: function(field) {
                      return [
                        field.item.scenario === "Trade rate"
                          ? _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.def.total,
                                      field.item.def.deaths
                                    )
                                  )
                                )
                              ]),
                              field.item.def.deaths
                                ? _c(
                                    "span",
                                    { staticClass: "text-muted small ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.def.total) +
                                          "/" +
                                          _vm._s(field.item.def.deaths) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.def.wins,
                                      field.item.def.total
                                    )
                                  )
                                )
                              ]),
                              field.item.def.total
                                ? _c(
                                    "span",
                                    { staticClass: "text-muted small ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.def.wins) +
                                          "/" +
                                          _vm._s(field.item.def.total) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }