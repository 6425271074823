var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Plant Performance")]),
    !this.datacollection
      ? _c("div", [_vm._v("No data")])
      : _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "flex-fill" },
            [
              _c("BarChart", {
                attrs: {
                  "chart-data": _vm.datacollection,
                  options: _vm.chartOptions
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filters ml-2" },
            [
              _c("b-form-group", {
                staticClass: "mb-4",
                attrs: { label: "Plants" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var ariaDescribedby = ref.ariaDescribedby
                      return [
                        _c("b-form-checkbox-group", {
                          attrs: {
                            "aria-describedby": ariaDescribedby,
                            options: _vm.filter_plants_modified,
                            name: "plants",
                            stacked: "",
                            switches: ""
                          },
                          on: { change: _vm.update },
                          model: {
                            value: _vm.plants,
                            callback: function($$v) {
                              _vm.plants = $$v
                            },
                            expression: "plants"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("b-form-group", {
                staticClass: "mb-4",
                attrs: { label: "Scenario (Economy)" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var ariaDescribedby = ref.ariaDescribedby
                      return [
                        _c("b-form-checkbox-group", {
                          attrs: {
                            "aria-describedby": ariaDescribedby,
                            options: _vm.filterScenarios,
                            name: "scenarios",
                            stacked: "",
                            switches: ""
                          },
                          on: { change: _vm.update },
                          model: {
                            value: _vm.scenarios,
                            callback: function($$v) {
                              _vm.scenarios = $$v
                            },
                            expression: "scenarios"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }