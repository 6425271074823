var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AppTable", {
        attrs: {
          items: _vm.generalStatsItems,
          fields: _vm.mapStatsFields,
          "tbody-tr-class": "cursor-pointer"
        },
        on: { "row-clicked": _vm.toggleDetails },
        scopedSlots: _vm._u([
          {
            key: "cell(wins)",
            fn: function(field) {
              return [
                _c("BIconCaretRightFill", {
                  staticClass: "toggle-details-icon",
                  class: field.detailsShowing ? "expanded" : "collapsed"
                }),
                _vm._v(" " + _vm._s(field.item.wins) + " ")
              ]
            }
          },
          {
            key: "cell(rounds_atk_won_perc)",
            fn: function(field) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("percentage")(
                        field.item.rounds_atk_won,
                        field.item.rounds_atk_won + field.item.rounds_atk_lost
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(rounds_def_won_perc)",
            fn: function(field) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("percentage")(
                        field.item.rounds_def_won,
                        field.item.rounds_def_won + field.item.rounds_def_lost
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "row-details",
            fn: function() {
              return [
                _c("AppTable", {
                  attrs: {
                    items: _vm.aggregated,
                    fields: _vm.fields,
                    "sort-by": "bombsite",
                    "sort-desc": false,
                    "tbody-tr-class": "cursor-pointer"
                  },
                  on: { "row-clicked": _vm.toggleDetails },
                  scopedSlots: _vm._u([
                    {
                      key: "thead-top",
                      fn: function() {
                        return [
                          _c(
                            "b-tr",
                            [
                              _c("b-th"),
                              _c("b-th", { attrs: { colspan: "3" } }, [
                                _vm._v("Atk")
                              ]),
                              _c("b-th", { attrs: { colspan: "3" } }, [
                                _vm._v("Def")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(bombsite)",
                      fn: function(field) {
                        return [
                          _c("BIconCaretRightFill", {
                            staticClass: "toggle-details-icon",
                            class: field.detailsShowing
                              ? "expanded"
                              : "collapsed"
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("bombsite")(field.item.bombsite)) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(atk_wins)",
                      fn: function(field) {
                        return [
                          _c("div", [
                            _c("strong", [_vm._v(_vm._s(field.item.atk_wins))]),
                            _c(
                              "span",
                              { staticClass: "text-muted small ml-1" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.atk_wins,
                                      field.item.atk_rounds
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(atk_losses)",
                      fn: function(field) {
                        return [
                          _c("div", [
                            _c("strong", [
                              _vm._v(_vm._s(field.item.atk_losses))
                            ]),
                            _c(
                              "span",
                              { staticClass: "text-muted small ml-1" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.atk_losses,
                                      field.item.atk_rounds
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(def_wins)",
                      fn: function(field) {
                        return [
                          _c("div", [
                            _c("strong", [_vm._v(_vm._s(field.item.def_wins))]),
                            _c(
                              "span",
                              { staticClass: "text-muted small ml-1" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.def_wins,
                                      field.item.def_rounds
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(def_losses)",
                      fn: function(field) {
                        return [
                          _c("div", [
                            _c("strong", [
                              _vm._v(_vm._s(field.item.def_losses))
                            ]),
                            _c(
                              "span",
                              { staticClass: "text-muted small ml-1" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("percentage")(
                                      field.item.def_losses,
                                      field.item.def_rounds
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "row-details",
                      fn: function(row) {
                        return [
                          _c("AppTable", {
                            attrs: {
                              items: row.item.items,
                              fields: _vm.detailFields,
                              "sort-by": "scenario",
                              "sort-desc": true,
                              "sort-compare": _vm.sortCompare,
                              "thead-class": "-d-none"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(atk_rounds)",
                                  fn: function(field) {
                                    return [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(_vm._s(field.item.atk.rounds))
                                        ])
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(atk_wins)",
                                  fn: function(field) {
                                    return [
                                      _c("div", [
                                        _c("strong", [
                                          _vm._v(_vm._s(field.item.atk.wins))
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-muted small ml-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("percentage")(
                                                  field.item.atk.wins,
                                                  field.item.atk.plants
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(atk_losses)",
                                  fn: function(field) {
                                    return [
                                      _c("div", [
                                        _c("strong", [
                                          _vm._v(_vm._s(field.item.atk.losses))
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-muted small ml-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("percentage")(
                                                  field.item.atk.losses,
                                                  field.item.atk.plants
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(def_rounds)",
                                  fn: function(field) {
                                    return [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(_vm._s(field.item.def.rounds))
                                        ])
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(def_wins)",
                                  fn: function(field) {
                                    return [
                                      _c("div", [
                                        _c("strong", [
                                          _vm._v(_vm._s(field.item.def.wins))
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-muted small ml-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("percentage")(
                                                  field.item.def.wins,
                                                  field.item.def.plants
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "cell(def_losses)",
                                  fn: function(field) {
                                    return [
                                      _c("div", [
                                        _c("strong", [
                                          _vm._v(_vm._s(field.item.def.losses))
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-muted small ml-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("percentage")(
                                                  field.item.def.losses,
                                                  field.item.def.plants
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ]
                      }
                    }
                  ])
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }